// import commonHelp from '@/util/help'

const colorList = () => {
    return ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa', '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050', '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'];
}
const labelSize = 22
export default {
    clearCanvas(el) {
        window.echarts.init(document.getElementById(el)).clear()
    },

    renderLine(el, list) {
        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption({
            animation: false,
            color: colorList(),
            tooltip: {
                trigger: 'axis',
            },
            xAxis: [{
                type: 'category',
                data: list.map(item => item.year),
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                }
                
            }, ],
            yAxis: [{
                type: 'value',
                scale: true,
                axisTick: { show: false },
                axisLine: { show: false },
                min: 0,
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                }
                
            }, ],
            series: [{
                type: 'line',
                data: list
                    .map(item => item.value)
                    .map(item => {
                        if (item == 0) {
                            item = ''
                        }
                        return item
                    }),
                smooth: true,
                connectNulls: true,
            }, ],
        })

        return myChart
    },

    renderMulLine(el, list = [], years) {
        let seriesData = [], legendData = [];
        list = list || []
        list.forEach(ele => {
            let data = []
            years.forEach(item => {
                if (ele[item]) {
                    data.push(ele[item])
                } else {
                    data.push('')
                }
            })
            legendData.push(ele.name)
            seriesData.push({
                name: ele.name,
                type: 'line',
                smooth: true,
                connectNulls: true,
                data,
            })
        });

        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption({
            animation: false,
            color: colorList(),
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: legendData,
                textStyle: {
                    color: '#000',
                    fontSize: labelSize,
                }
            },
            xAxis: [{
                type: 'category',
                data: years,
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                }
            }, ],
            yAxis: [{
                type: 'value',
                scale: true,
                axisTick: { show: false },
                axisLine: { show: false },
                min: 0,
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
            }, ],
            series: seriesData,
        })

        return myChart
    },

    renderMulBar(el, list = [], years) {
        let seriesData = [], legendData = [];
        list = list || []
        list.forEach(ele => {
            let data = []
            years.forEach(item => {
                if (ele[item]) {
                    data.push(ele[item])
                } else {
                    data.push('')
                }
            })
            legendData.push(ele.name)
            seriesData.push({
                name: ele.name,
                type: 'bar',
                connectNulls: true,
                data,
                // itemStyle: {
                //     normal: {
                //         label: {
                //             show: true,
                //             textStyle: {
                //                 color: '#000',
                //                 fontSize: labelSize,
                //             }
                //         },
                //     }
                // }
            })
        });

        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption({
            animation: false,
            color: colorList(),
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: legendData,
                textStyle: {
                    color: '#000',
                    fontSize: labelSize,
                }
            },
            xAxis: [{
                type: 'category',
                data: years,
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                }
            }, ],
            yAxis: [{
                type: 'value',
                scale: true,
                min: 0,
                axisTick: { show: false },
                axisLine: { show: false },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                }
            }, ],
            series: seriesData,
        })

        return myChart
    },

    renderMulLineRatio(el, list = [], years) {
        let seriesData = [], legendData = [];
        list = list || []
        list.forEach(ele => {
            let data = []
            years.forEach(item => {
                data.push((ele[item] * 100).toFixed(2))
            })
            legendData.push(ele.name)
            seriesData.push({
                name: ele.name,
                type: 'line',
                smooth: true,
                connectNulls: true,
                data,
                itemStyle: {
                    normal: {
                        label: {
                            show: true,
                            textStyle: {
                                color: '#000',
                                fontSize: labelSize,
                            }
                        },
                    }
                }
            })
        });

        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption({
            animation: false,
            color: colorList(),
            tooltip: {
                trigger: 'axis',
                formatter: function(seriesData) {
                    let showHtm = "";
                    for (let i = 0; i < seriesData.length; i++) {
                        //名称
                        let name = seriesData[i].seriesName;
                        //x轴名称
                        let text = seriesData[i].name;
                        //值
                        let value = seriesData[i].value;
                        if (i === 0) {
                            showHtm = text + '<br>';
                        }
                        showHtm += name + ': ' + value + '%' + '<br>'
                    }
                    return showHtm;
                }
            },
            legend: {
                data: legendData,
                textStyle: {
                    color: '#000',
                    fontSize: labelSize,
                }
            },
            xAxis: [{
                type: 'category',
                data: years,
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                }
            }, ],
            yAxis: [{
                type: 'value',
                scale: true,
                min: 0,
                axisTick: { show: false },
                axisLine: { show: false },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                axisLabel: {
                    color: '#000',
                    fontSize: labelSize,
                    show: true,
                    interval: 'auto',
                    formatter: '{value} %' //纵坐标百分比
                }
            }, ],
            series: seriesData,
        })

        return myChart
    },

    renderPie(el, arr = []) {
        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption({
            animation: false,
            color: colorList(),
            tooltip: {
                trigger: 'item',
                formatter:function(data){
                    return `${data.name} ${data.percent.toFixed(2)}%`
                },
            },
            // legend: {
            //     left: 'center',
            //     bottom: '5%',
            // },
            series: [{
                type: 'pie',
                radius: '50%',
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                // label: {
                //     //echarts饼图内部显示百分比设置
                //     show: true,
                //     position: "inside", //outside 外部显示  inside 内部显示
                //     formatter:function(data){
                //         // return `${data.name} \n ${data.percent.toFixed(2)}%`
                //         return `${data.percent.toFixed(2)}%`
                //     },
                //     color: "#ffffff", //颜色
                //     fontSize: 12 //字体大小
                // },
                label: {
                    alignTo: 'edge',
                    lineHeight: labelSize + 4,
                    fontSize: labelSize,
                    margin: 0,
                    formatter: item => {
                        // if (item.data.amount) {
                        //     return `${item.name}：¥${item.data.amount} ${item.percent}%\n`
                        // }
                        return `${item.name}\r\n${item.percent}%`
                    },
                    rich: {
                        time: {
                            fontSize: labelSize,
                            color: '#000'
                        }
                    }
                },
                data: arr,
                labelLayout: function (params) {
                    const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                    const points = params.labelLinePoints;
                    // Update the end point.
                    points[2][0] = isLeft
                        ? params.labelRect.x
                        : params.labelRect.x + params.labelRect.width;
                    return {
                        labelLinePoints: points
                    };
                },
            }]
        })
        return myChart
    },

    renderBarLine(el, barList = [], lineList = [], years) {
        let seriesData = [], legendData = [];

        barList.forEach(ele => {
            let data = []
            years.forEach(item => {
                if (ele[item]) {
                    data.push(ele[item])
                } else {
                    data.push('')
                }
            })
            legendData.push(ele.name)
            seriesData.push({
                name: ele.name,
                type: 'bar',
                connectNulls: true,
                data,
            })
        });
        lineList.forEach(ele => {
            let data = []
            years.forEach(item => {
                data.push(
                    parseFloat((ele[item] * 100).toFixed(2))
                )
            })
            legendData.push(ele.name)
            seriesData.push({
                name: ele.name,
                type: 'line',
                smooth: true,
                connectNulls: true,
                data,
                yAxisIndex: 1,
            })
        })

        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        const option = {
            animation: false,
            color: colorList(),
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: legendData,
                textStyle: {
                    color: '#000',
                    fontSize: labelSize,
                }
            },
            xAxis: [{
                type: 'category',
                data: years,
                axisLabel: {
                    show: true,
                    color: '#000',
                    fontSize: labelSize,
                }
            }, ],
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    min: 0,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        show: true,
                        color: '#000',
                        fontSize: labelSize,
                    }
                },
                {
                    type: 'value',
                    scale: true,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        show: true,
                        interval: 'auto',
                        color: '#000',
                        fontSize: labelSize,
                        formatter: '{value} %' //纵坐标百分比
                    }
                },
            ],
            series: seriesData,
        }
        myChart.setOption(option)

        return myChart
    },

    renderMinBarLine(el, bar, line, quarters) {
        let seriesData = [], legendData = [];

        // bar
        legendData.push(bar.name)
        seriesData.push({
            name: bar.name,
            type: 'bar',
            connectNulls: true,
            data: quarters.map(item => bar[item.key]),
        })

        // line
        legendData.push(line.name)
        seriesData.push({
            name: line.name,
            type: 'line',
            smooth: true,
            connectNulls: true,
            yAxisIndex: 1,
            data: quarters.map(item => line[item.key]),
        })

        const myChart = window.echarts.init(document.getElementById(el))
        myChart.clear()
        myChart.setOption({
            animation: false,
            color: colorList(),
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: legendData,
                textStyle: {
                    color: '#000',
                    fontSize: labelSize,
                }
            },
            xAxis: [{
                type: 'category',
                data: quarters.map(item => item.value),
                axisLabel: {
                    //  X 坐标轴标签相关设置
                    interval: 0,
                    rotate: '90',
                    color: '#000',
                    fontSize: 12,
                }
            }, ],
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    min: 0,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        show: true,
                        color: '#000',
                        fontSize: labelSize,
                    }
                },
                {
                    type: 'value',
                    scale: true,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    // min: 0,
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        show: true,
                        color: '#000',
                        fontSize: labelSize,
                        interval: 'auto',
                        formatter: '{value} %' //纵坐标百分比
                    }
                },
            ],
            series: seriesData,
        })

        return myChart
    },

    colorList: colorList(),

    // 获取前20条数据
    getFrontData(list, len = 20) {
        if  (list && list.length > len) {
            return list.splice(0, len)
        } else {
            return list || []
        }
    },
}