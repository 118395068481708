<template>
    <div :class="{'loading-panel': loading}" v-loading="loading" style="display: block">
        <div class="word-max clickable" @click="maxFrame">最大化Word</div>
        <div class="word-min clickable" @click="minFrame">复原Word</div>
        <div id="corp-word"></div>
        <div style="opacity: 0;" v-if="loading">
            <div id="canvas1" class="my-canvas"></div>
            <div id="canvas2" class="my-canvas"></div>
            <div id="canvas3" class="my-canvas"></div>
        </div>
    </div>
</template>

<script>
    import canvasHelp from './help'
    export default {
        props: {
            code: {},
        },
        data() {
            return {
                loading: true,
                max: false,
                docParam: {},
            }
        },
        mounted() {
            this.$api.get('report/corpSysUserReport/queryBaseParamByCompCode', {compCode: this.code})
                .then(res => {
                    const list = []
                    let index = 2
                    while (true) {
                        const val = res.data.metaInfo.year[index.toString()]
                        if (val) {
                            list.push(parseInt(val))
                            index++
                        } else {
                            break
                        }
                    }
                    canvasHelp.renderLine('canvas1', res.data.saleInfo.salesTrend || [])
                    canvasHelp.renderMulLine('canvas2', res.data.saleInfo.drugChannelAmount || [], list)
                    canvasHelp.renderMulLineRatio('canvas3', res.data.saleInfo.drugChannelRatio || [], list)
                    setTimeout(() => {
                        this.$api.post('report/sysUserReport/createWord', {
                            compCode: this.code,
                            reportType: 'COMP_TYPE',
                            param: {
                                compSalesTrendPic: this.getBase64ByEcharts('canvas1'),
                                drugChannelAmountPic: this.getBase64ByEcharts('canvas2'),
                                drugChannelRatioPic: this.getBase64ByEcharts('canvas3'),
                            }
                        })
                            .then(res => {
                                this.docParam = res.data || {}
                                setTimeout(() => {
                                    this.loadWord()
                                }, 10)
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }, 1000)

                })


        },
        methods: {
            getBase64ByEcharts(id) {
                return window.echarts.init(document.getElementById(id)).getDataURL().replace('data:image/png;base64,', '')
                // return window.echarts.init(document.getElementById(id)).getDataURL()
            },
            loadWord() {
                const fullHeight = document.querySelector('.taoshu-body').clientHeight
                const tabHeight = document.querySelector('.general-template__tab').clientHeight
                const conditionHeight = document.querySelector('.general-template__condition').clientHeight
                new window.DocsAPI.DocEditor("corp-word", {
                    "width": "100%",
                    "height": `${fullHeight - tabHeight - conditionHeight - 75}px`,
                    "type": "desktop",
                    "token": "",
                    "document": {
                        "title": this.docParam.title,
                        "url":  this.docParam.url,
                        "fileType": "docx",
                        "key": this.docParam.key,
                        "info": {
                            "owner": "Me",
                            "favorite": null
                        },
                        "permissions": {
                            "comment": false,
                            "copy": true,
                            "download": true,
                            "edit": true,
                            "print": false,
                            "fillForms": true,
                            "modifyFilter": true,
                            "modifyContentControl": true,
                            "review": true,
                            "reviewGroups": null,
                            "commentGroups": {},
                            "userInfoGroups": null
                        }
                    },
                    "editorConfig": {
                        spellcheck: false,
                        "actionLink": null,
                        "mode": "edit",
                        "lang": "zh",
                        "callbackUrl": `${window.location.origin}/taoshu-data/saveReport?reportType=COMP_TYPE&compCode=${this.code}&token=${this.$help.token.get()}`,
                        "user": {
                            "id": this.$store.state.user.id,
                            "name": this.$store.state.user.userName
                        },

                        "customization": {
                            "about": false,
                            "comments": false,
                            "feedback": false,
                            "forcesave": true,
                            // "goback": {
                            //     "url": "http://localhost/example/"
                            // },
                            "submitForm": false
                        },
                        "fileChoiceUrl": "",
                        "plugins": {
                            "pluginsData":[
                                '/officePlugin/test/config.json'
                            ]
                        }
                    }
                });
            },
            maxFrame() {
                this.max = true
                document.querySelector('iframe').className = 'max-frame'
            },
            minFrame() {
                this.max = false
                document.querySelector('iframe').className = ''
            },
        },
    }
</script>